<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region == true"  @change="(event) => updateOfficeDropdown(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" clearable dense :disabled="disabled_office == true" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2 col-8">
                                <v-select v-model="usersModel" :items="usersLists" default="" item-value="user_id" item-text="user_id" label="Users" clearable dense :disabled="disabled_users == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-1 col-4">
                                <v-select v-model="pr_statModel" :items="pr_statLists" default="" item-value="stat" item-text="stat_text" label="Status" clearable dense prepend-icon="mdi-check-circle-outline"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-1 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>

            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">Detail of {{ detailModalTitle }}</h5>
                </div>
                <div class="modal-body">
                            
                        <v-data-table :headers="headers" :items="itemDetailLists" class="elevation-1" page-count="5" :search="search" :loading="loadingDetail" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Purchase Analysis',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            usersModel: '',
            usersLists: [],
            pr_statModel: '',
            pr_statLists: [
                {
                    stat: '',
                    stat_text: 'All'
                },
                {
                    stat: 'O',
                    stat_text: 'Open'
                },
                {
                    stat: 'C',
                    stat_text: 'Close'
                },
            ],
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            dateFromFormatted: '',
            dateToFormatted: '',
            disabled_region: true,
            disabled_office: true,
            disabled_users: true,
            headers : [],
            itemDetailLists: [],
            search: '',
            detailModalTitle:'',
            loadingDetail: false,
            userGroup: null,
            appl_id: 'ANALYSIS'
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }

                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }

                if(res.data.users.length == 1){
                    this.usersModel = res.data.users[0].user_id
                    this.usersLists = res.data.users
                    this.disabled_users = true
                } else {
                    this.usersLists = res.data.users
                    this.disabled_users = false
                }

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            
        },

        async getChartData(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getChartData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.data.length != 0){
                    document.querySelector(".cardGraph").style.display = "block";
                    this.renderChart(res.data.data5, res.data.data6, res.data.data7, res.data.data8)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        renderChart(data, data2, data3, data4){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: "Purchase Analysis Graph",
                },
                subtitles: [{
                    text: "Click for details",		
                    fontColor: "grey",
                }],
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "DAYS",
                    gridColor: "#005f73"
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.PR_APRV,
                        showInLegend: true,
                        name: "PR->APRV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data,
                    },
                    {
                        type: "column",
                        click: this.APRV_PO,
                        showInLegend: true,
                        name: "APRV->PO",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data2,
                    },
                    {
                        type: "column",
                        click: this.PO_RCV,
                        showInLegend: true,
                        name: "PO->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data3,
                    },
                    {
                        type: "column",
                        click: this.PR_RCV,
                        showInLegend: true,
                        name: "PR->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        dataPoints: data4,
                    }
                ]
            });

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        updateOfficeDropdown(id){

            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getOfficeById?regionid=all`, { 
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        async PR_APRV(e) {
            
            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase to Approve Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE PR', value: 'dt_pru', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE APRV', value: 'dt_aprv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PR_APRV',
                    period: e.dataPoint.label,
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : ''
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async APRV_PO(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Approve to Purchase Order Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE APRV', value: 'dt_aprv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE PO', value: 'dt_po', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'APRV_PO',
                    period: e.dataPoint.label,
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async PO_RCV(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase Order to Receive Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE PO', value: 'dt_po', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE RCV', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PO_RCV',
                    period: e.dataPoint.label,
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async PR_RCV(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase Request to Receive Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE PR', value: 'dt_pru', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE RCV', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PR_RCV',
                    period: e.dataPoint.label,
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        closeDialogModalDetail(){

            this.dialogModalDetail = false

        },

        filterText (value, search , item) {
            return value != null &&
            search  != null &&
            typeof value === 'string' &&
            value.toString().indexOf(search) !== -1
        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }


</style>